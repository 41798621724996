<template>
  <div class="table_wrapper">
    <v-btn color="primary" class="mb-5" :to="'/currencies/new'">New</v-btn>
    <v-btn
      color="light-blue"
      class="mb-5 ml-2"
      dark
      @click="getDataFromApi(activeTab)"
      >Refresh List</v-btn
    >
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :items-per-page="perPage"
      :options.sync="options"
      show-select
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Currency List</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.c6`]="{ item }">
        <v-chip :color="item.c6 ? 'success' : 'error'" dark>
          {{ item.c6 ? "Yes" : "No" }}
        </v-chip>
      </template>

      <template v-slot:[`item.c9`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/currencies/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import moment from "moment";
export default {
  name: "Currencies",
  data() {
    return {
      dialog: false,
      selected: [],
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Name", value: "c2", sortable: false },
        { text: "Code", value: "c3", sortable: false },
        { text: "Symbol", value: "c4", sortable: false },
        { text: "USD Rate", value: "c5", sortable: false },
        { text: "Active?", value: "c6", sortable: false },
        { text: "Created at", value: "c7", sortable: false },
        { text: "Updated at", value: "c8", sortable: false },
        { text: "Action", value: "c9", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 20,
      itemsData: [],
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = process.env.VUE_APP_API_URL + "currency/bulkdelete";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.name,
            c3: item.code,
            c4: item.symbol,
            c5: item.usd_rate,
            c6: item.is_enabled,
            c7: this.parseDateTime(item.created_at),
            c8: this.parseDateTime(item.updated_at),
            id: item.id,
            item: item,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          resolve({
            itemsR,
            total,
          });
        });
      });
    },
    async getDesserts() {
      const fields = [];
      const sort = [];

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "currency/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        );
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
